import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useMutation, useQuery } from '@/app/common/graphql/hooks'
import tracking from '@/helpers/tracking'
import {
  ChangeCustomerPasswordFromTokenDocument,
  ChangeCustomerPasswordFromTokenMutation,
  ChangeCustomerPasswordFromTokenMutationVariables,
  GetCurrentCustomerDocument,
  GetCurrentCustomerQuery,
  GetCurrentCustomerQueryVariables,
  LoginCustomerDocument,
  LoginCustomerMutation,
  LoginCustomerMutationVariables,
  RegisterCustomerDocument,
  RegisterCustomerMutation,
  RegisterCustomerMutationVariables,
} from '@/types/gql/graphql'
import {
  customerAuthFinishedState,
  customerState,
  destroyCustomerToken,
  getCustomerToken,
  setCustomerToken,
} from './auth.state'

export const useCheckAndSetCurrentCustomer = () => {
  const customerToken = getCustomerToken()
  const [customer, setCustomer] = useRecoilState(customerState)
  const [customerAuthFinished, setCustomerAuthFinished] = useRecoilState(
    customerAuthFinishedState,
  )

  const { data } = useQuery<
    GetCurrentCustomerQuery,
    GetCurrentCustomerQueryVariables
  >(GetCurrentCustomerDocument, {
    skip: !customerToken || !!customerAuthFinished,
    onCompleted: (data) => {
      const customer = data?.getCurrentCustomer
      if (data && customer) {
        setCustomer({
          ...data.getCurrentCustomer,
          hasAlreadyDeals: data.getCurrentCustomer.hasAlreadyDeals,
          token: customerToken,
        })
        setCustomerAuthFinished(true)

        Sentry.setUser({
          id: customer._id,
          email: customer.email,
        })
        tracking.userId({ userId: customer._id })
      }
    },
    onError: () => {
      setCustomer(undefined)
      setCustomerAuthFinished(true)
    },
  })

  useEffect(() => {
    if (!customerToken) {
      setCustomer(undefined)
      setCustomerAuthFinished(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToken])

  if (!customerToken) return undefined

  if (customer) return customer

  return data?.getCurrentCustomer
}

export const useLoginCustomer = () => {
  const [loginCustomer, loginCustomerRes] = useMutation<
    LoginCustomerMutation,
    LoginCustomerMutationVariables
  >(LoginCustomerDocument, {
    disableDefaultErrorHandling: true,
  })
  const setCustomer = useSetRecoilState(customerState)

  const customer = loginCustomerRes.data?.loginCustomer

  if (customer && customer.token) {
    setCustomerToken(customer.token)
    setCustomer({
      ...customer,
      hasAlreadyDeals: customer.deals ? customer.deals.length > 0 : false,
    })
  }

  return {
    customer,
    error: loginCustomerRes.error,
    loginCustomer,
  }
}

export const useLogoutCustomer = () => {
  const router = useRouter()
  const setCustomer = useSetRecoilState(customerState)

  return () => {
    destroyCustomerToken()
    setCustomer(undefined)

    // Base routes that require login
    const routesRequiringLogin = ['/profile']

    const isPageRequiringLogin = routesRequiringLogin.some((route) =>
      router.pathname.startsWith(route),
    )

    if (isPageRequiringLogin) {
      router.push('/')
    }
  }
}

export const useRegisterCustomer = () => {
  const [registerCustomer, registerCustomerRes] = useMutation<
    RegisterCustomerMutation,
    RegisterCustomerMutationVariables
  >(RegisterCustomerDocument, {
    disableDefaultErrorHandling: true,
  })
  const setCustomer = useSetRecoilState(customerState)

  const customer = registerCustomerRes.data?.registerCustomer

  useEffect(() => {
    if (customer && customer.token) {
      setCustomerToken(customer.token)
      setCustomer({
        ...customer,
        hasAlreadyDeals: false,
      })
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.token])

  return {
    customer,
    error: registerCustomerRes.error,
    registerCustomer,
    loading: registerCustomerRes.loading,
  }
}

export const useResetCustomerPassword = () => {
  const setCustomer = useSetRecoilState(customerState)
  const [resetCustomerPassword, resetCustomerPasswordRes] = useMutation<
    ChangeCustomerPasswordFromTokenMutation,
    ChangeCustomerPasswordFromTokenMutationVariables
  >(ChangeCustomerPasswordFromTokenDocument, {
    disableDefaultErrorHandling: true,
    onCompleted: (data) => {
      if (data && data.changeCustomerPasswordFromToken.lastname) {
        const customer = data.changeCustomerPasswordFromToken
        setCustomerToken(customer.token as string)
        setCustomer({ ...customer, hasAlreadyDeals: true })
      }
    },
  })

  const customer =
    resetCustomerPasswordRes.data?.changeCustomerPasswordFromToken

  return {
    customer,
    error: resetCustomerPasswordRes.error,
    resetCustomerPassword,
  }
}
