import { useImageSizes } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { CaptionedImageStoryblok } from '@/types/storyblok-component-types'

export default function CaptionedImage({
  blok,
}: {
  blok: CaptionedImageStoryblok
}) {
  const sizes = useImageSizes(39, ['md', '100vw'])

  return (
    <Container $imagePosition={blok.imagePosition} {...storyblokEditable(blok)}>
      <ImageWrapper $hideOnMobile={blok.hide_image_on_mobile ?? false}>
        {blok.image?.filename && (
          <StoryblokImage image={blok.image} sizes={sizes} />
        )}
      </ImageWrapper>
      <TextWrapper $textCenter={blok.center_caption_on_mobile ?? true}>
        <RichTextWrapper>
          <StoryblokRichText document={blok.caption} />
        </RichTextWrapper>
      </TextWrapper>
    </Container>
  )
}

const ImageWrapper = styled.div<{ $hideOnMobile: boolean }>`
  flex: 1 0 0px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: ${(props) => (props.$hideOnMobile ? 'none' : 'block')};

  ${media.md} {
    display: block !important;
    flex: 7 0 0px;
  }
`

const TextWrapper = styled.div<{ $textCenter: boolean }>`
  flex: 1 0 0px;
  display: flex;
  align-items: ${(props) => (props.$textCenter ? 'center' : 'flex-start')}; /* Center text vertically if $textCenter is true */
  justify-content: ${(props) => (props.$textCenter ? 'center' : 'flex-start')}; /* Center text horizontally on mobile/tablet */
  text-align: ${(props) => (props.$textCenter ? 'center' : 'left')} !important;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  ${media.md} {
    flex: 5 0 0px;
    align-items: center;
    justify-content: flex-start;
`

const Container = styled.div<{
  $imagePosition: 'left' | 'right' | ''
}>`
  width: 100%;

  b {
    font-weight: 600;
  }

  h2 {
    font-size: 1.375rem;

    ${media.md} {
      font-size: 2.375rem !important;
    }
  }

  p {
    letter-spacing: 0em;
    line-height: 1.4;
  }

  display: flex;
  flex-direction: column;

  ${media.md} {
    flex-direction: ${(props) =>
      props.$imagePosition === 'left' ? 'row' : 'row-reverse'} !important;
  }
`

const RichTextWrapper = styled(RichTextParagraphStyles)`
  & a:last-of-type {
    ${media.md} {
      margin-top: 1rem !important;
    }
  }
`
