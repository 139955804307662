import WhatsappBubble from '../WhatsappBubble/WhatsappBubble'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import Footer from './Footer'
import Header from './Header'

interface LayoutProps {
  children: React.ReactNode
}

const FlexLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentSpacer = styled.div<{
  $isProfilePage?: boolean
  $isCheckoutPage?: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isProfilePage &&
    `
    ${media.md} {
      min-height: calc(100vh - 100px) !important; /* 100px (header) */
    }
  `}

  ${(props) =>
    !props.$isCheckoutPage &&
    !props.$isProfilePage &&
    ` 
     @media (max-width: 991px) {
      margin-top: -2.2rem;
    }
  `}
`

const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()
  return (
    <FlexLayout>
      <Header />
      <ContentSpacer
        $isProfilePage={router.route.startsWith('/profile')}
        $isCheckoutPage={router.route.startsWith('/checkout')}
      >
        {children}
      </ContentSpacer>
      <Footer />
      <WhatsappBubble />
    </FlexLayout>
  )
}

export default Layout
