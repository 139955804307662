import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { LeftArrow, RightArrow } from '@/style/components/Arrow/Arrow'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import ProfileIcon from './ico_ProfileDefault.svg'

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 110px);

  ${media.sm} {
    width: 700px;
    height: 698px;
    margin-top: 0px;
    border-radius: 13px;
  }

  ${media.md} {
    width: 860px;
    height: 698px;
    margin-top: 0px;
    border-radius: 13px;
  }
`

const ProfileIconStyled = styled(ProfileIcon)`
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const StyledRow = styled(Row)`
  height: 100%;
  align-content: stretch;
  margin: 0;
  display: block;

  ${media.sm} {
    display: flex !important;
  }
`

const SideNavbar = styled(Col)`
  display: none !important;

  ${media.md} {
    hyphens: auto;
    color: white;
    display: flex !important;
    position: relative;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.primary};
    background-size: cover;
    background-position: center top;
    align-self: stretch;
    justify-content: start;
    padding: 0 2.2rem;
    padding-top: 200px;
  }
`

const FormCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  padding: 0;

  ${media.sm} {
    height: 650px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const FormWrapper = styled.div`
  margin-left: 6%;
  margin-bottom: 1rem;
  width: 88%;
  text-align: center;
`

const MobileHeader = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  background: white;
  border-radius: 13px;

  ${media.sm} {
    display: block;
    padding-top: 14px;
    height: 48px;
  }

  ${media.md} {
    display: none;
  }
`

const MobileNavbar = styled.div<{ $fixedMobileFooter?: boolean }>`
  color: ${(props) => props.theme.colors.white};
  margin-top: auto;
  text-align: center;
  padding-top: 15px;
  height: 90px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  position: ${(props) => (props.$fixedMobileFooter ? 'fixed' : 'static')};
  bottom: 0;

  ${media.md} {
    display: none;
  }
`

const MobileNavbarInner = styled.div`
  margin-top: auto;
`

const MobileSignupButton = styled(Text.md)`
  display: inline-block;
  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  margin-top: 8px;
  margin-bottom: 8px;
  &:hover {
    cursor: pointer;
  }
`

const RegisterLinkButton = styled(Text.md)`
  margin-top: 11px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`
const Divider = styled.hr`
  margin: 22px 0;
  border: 1px solid #4877fc;
`

const NavHeadline = styled(Text.lg)`
  font-size: 1.75rem;
  word-wrap: break-word !important;
`

const NavLinkText = styled(Text.md)`
  word-wrap: break-word !important;
  hyphens: none;
`

const BackButton = styled.span`
  position: absolute;
  top: 14px;
  left: 20px;
  cursor: pointer;
  z-index: 3;

  ${media.sm} {
    top: 15px;
  }
`

const MobileNavbarTitle = styled(Text.lg)`
  display: none;

  ${media.sm} {
    display: block;
  }
`

interface AuthDialogProps {
  content: JSX.Element
  sideIcon?: JSX.Element
  hideProfileIcon?: boolean
  fixedMobileFooter?: boolean
  navBar: {
    title: string
    link?: {
      description: string
      text: string
      onClick: () => void
    }
    backButton?: () => void
  }
}

const AuthDialog: React.FC<AuthDialogProps> = (props) => {
  const { content, navBar, sideIcon, hideProfileIcon, fixedMobileFooter } =
    props

  return (
    <Wrapper>
      <StyledRow>
        <MobileHeader>
          {navBar.backButton && (
            <BackButton onClick={navBar.backButton}>
              <LeftArrow fontSize="small" />
            </BackButton>
          )}
          <MobileNavbarTitle weight="semibold">
            {navBar.title}
          </MobileNavbarTitle>
        </MobileHeader>
        <SideNavbar md={4} sm={0} xs={0}>
          {!hideProfileIcon && <ProfileIconStyled />}
          <NavHeadline weight="semibold" as="h2" lang="de">
            {navBar.title}
          </NavHeadline>
          <Divider />
          {navBar.link && (
            <NavLinkText as="p">{navBar.link.description}</NavLinkText>
          )}
          {navBar.link && (
            <RegisterLinkButton as="h2" onClick={navBar.link.onClick}>
              {navBar.link.text} <RightArrow fontSize="small" />
            </RegisterLinkButton>
          )}
          {sideIcon}
        </SideNavbar>
        <FormCol md={8} sm={12} xs={12}>
          <FormWrapper>{content}</FormWrapper>
          <MobileNavbar $fixedMobileFooter={fixedMobileFooter}>
            {navBar.link && (
              <MobileNavbarInner>
                <Text.md as="p">{navBar.link.description}</Text.md>
                <MobileSignupButton
                  weight="semibold"
                  as="span"
                  onClick={navBar.link.onClick}
                >
                  {navBar.link.text}
                </MobileSignupButton>
              </MobileNavbarInner>
            )}
          </MobileNavbar>
        </FormCol>
      </StyledRow>
    </Wrapper>
  )
}

export default AuthDialog
