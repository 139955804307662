import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { FunctionComponent, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useQuery } from '@/app/common/graphql/hooks'
import useRegionCode from '@/helpers/useRegionCode'
import { media } from '@/style/helpers'
import {
  ERegionCode,
  GetMaintenanceWindowDocument,
  GetMaintenanceWindowQuery,
  GetMaintenanceWindowQueryVariables,
} from '@/types/gql/graphql'
import { useLocalStorage } from '@/utils/misc'

interface UseInfoPopupProps {
  localStorageKey: string
  content: (key: any, handleClose: (key: any) => void) => React.ReactNode
  hidePopup?: boolean
}

const useInfoPopup = ({
  localStorageKey,
  content,
  hidePopup,
}: UseInfoPopupProps) => {
  const [infoPopup, setInfoPopup] = useLocalStorage<boolean>(localStorageKey)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleClose = (key: any) => {
    closeSnackbar(key)
    setInfoPopup(true)
  }

  const handleSnackbar = () => {
    if (!infoPopup && !hidePopup) {
      enqueueSnackbar('', {
        preventDuplicate: true,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        content: (key) => content(key, handleClose),
      })
    }
    return null
  }

  useEffect(() => {
    handleSnackbar()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePopup])

  return { handleClose }
}

const InfoPopup: FunctionComponent = () => {
  const regionCode = useRegionCode()

  const showPopupAt1 = useMemo(() => {
    const date = dayjs()
    return (
      date.isAfter(dayjs('2023-11-12 08:00', 'YYYY-MM-DD HH:mm')) &&
      date.isBefore(dayjs('2023-11-12 12:59:59', 'YYYY-MM-DD HH:mm:ss'))
    )
  }, [])
  const showPopupAt2 = useMemo(() => {
    const date = dayjs()
    return (
      date.isAfter(dayjs('2023-11-12 13:00', 'YYYY-MM-DD HH:mm')) &&
      date.isBefore(dayjs('2023-11-15 23:59:59', 'YYYY-MM-DD HH:mm:ss'))
    )
  }, [])

  const content = (key: any, handleClose: (key: any) => void) => {
    return (
      <SnackbarBox>
        <SnackbarContent>
          <br />
          {regionCode === ERegionCode.At && (
            <>
              <HeadStyle style={{ textAlign: 'center', fontSize: 18 }}>
                GAK-Gewinnspiel vom 12.11.2023
              </HeadStyle>
              {showPopupAt1 && <BodyAT1 />}

              {showPopupAt2 && <BodyAT2 />}
            </>
          )}
        </SnackbarContent>
        <CloseButton onClick={() => handleClose(key)}>
          <HighlightOffRoundedIcon />
        </CloseButton>
      </SnackbarBox>
    )
  }

  useInfoPopup({
    localStorageKey: showPopupAt1
      ? 'GAK_Gewinnspiel_12_11_2023_1'
      : 'GAK_Gewinnspiel_12_11_2023_2',
    content,
    hidePopup:
      regionCode === ERegionCode.De ||
      (regionCode === ERegionCode.At && !showPopupAt1 && !showPopupAt2),
  })

  return null
}

export const MaintenanceInfoPopup = () => {
  const maintenanceWindow = useQuery<
    GetMaintenanceWindowQuery,
    GetMaintenanceWindowQueryVariables
  >(GetMaintenanceWindowDocument, {
    enableCaching: true,
  })

  const scheduledStartTime =
    maintenanceWindow.data?.getMaintenanceWindow?.scheduledStartTime
  const duration = maintenanceWindow.data?.getMaintenanceWindow?.duration ?? 20

  const content = (key: any, handleClose: (key: any) => void) => {
    return (
      <SnackbarBox>
        <SnackbarContent style={{ textAlign: 'center' }}>
          <br />
          Wir werden heute zwischen{' '}
          <b>{dayjs(scheduledStartTime).format('HH:mm')}</b> und{' '}
          <b>
            {dayjs(scheduledStartTime).add(duration, 'minutes').format('HH:mm')}
          </b>{' '}
          planmäßige Wartungsarbeiten durchführen.
          <br />
          Unser Service wird in dieser Zeit nicht zur Verfügung stehen.
        </SnackbarContent>
        <CloseButton onClick={() => handleClose(key)}>
          <HighlightOffRoundedIcon />
        </CloseButton>
      </SnackbarBox>
    )
  }

  useInfoPopup({
    localStorageKey: 'maintenance_popup',
    content,
    hidePopup:
      !scheduledStartTime ||
      !duration ||
      dayjs(scheduledStartTime).diff(dayjs(), 'minutes') > 60,
  })

  return null
}

export default InfoPopup

const BodyAT1 = () => (
  <>
    <p style={{ fontSize: 16, textAlign: 'center' }}>
      Der Gewinncode wird ab 13 Uhr hier angezeigt.
    </p>
    <p style={{ fontSize: 16, textAlign: 'center' }}>
      Abholung des Gewinns ab 13.11. im CASHY Shop Graz möglich.
    </p>
  </>
)

const BodyAT2 = () => (
  <>
    <p style={{ fontSize: 16, textAlign: 'center' }}>
      Der Gewinncode lautet: 1109
    </p>
    <p style={{ fontSize: 16, textAlign: 'center' }}>
      Abholung ab 13.11. im CASHY Shop Graz möglich.
    </p>
    <p style={{ fontSize: 16, textAlign: 'center' }}>Herzlichen Glückwunsch!</p>
  </>
)

const SnackbarBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
  box-shadow: 0 0 1rem rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
`
const SnackbarContent = styled.div`
  display: inline-block;

  padding: 0.75rem;
  color: white;
  font-size: 0.75rem;
  line-height: 1.4;

  ${media.sm} {
    padding: 1.5rem;
    font-size: 0.875rem;
  }

  b {
    font-weight: 600;
  }
`

const HeadStyle = styled.h3`
  font-weight: bold;
  margin-bottom: 8pt;
  font-size: medium;
  color: #01ff6c;
`

const CloseButton = withStyles({
  root: {
    color: 'white',
    position: 'absolute',
    right: '0',
    minWidth: 'auto',
    '&:focus, &:hover, &$active': {
      backgroundColor: 'initial',
    },
  },
})(Button)
