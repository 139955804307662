import useTranslation from 'next-translate/useTranslation'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { H2Header } from '@/style/components/H2Header'
import { SectionInner, SectionWrapper } from '@/style/components/Section'
import { media } from '@/style/helpers'
import {
  AboutPageMilestoneStoryblok,
  AssetStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'
import { dayjs } from '@/utils/time'
import Step from './Step'
import Steps from './Steps'

const Milestone = ({
  milestones,
}: {
  milestones: Array<AboutPageMilestoneStoryblok>
}) => {
  const { t } = useTranslation()

  const milestonesData = useMemo(
    () => getMilestonesData(milestones),
    [milestones],
  )

  return (
    <MainWrapper>
      <SectionWrapper sizing="xlarge">
        <SectionInner textAlign="center">
          <H2Header>{t('about:milestone.title')}</H2Header>
        </SectionInner>
        <SectionInner>
          {milestonesData.map((milestoneData) => {
            return (
              <Steps year={milestoneData[0]}>
                {milestoneData[1].map((milestone, index) => {
                  return (
                    <Step
                      date={milestone.dateAndMonth}
                      text={milestone.text}
                      image={milestone.image}
                      showArrow={index % 2 !== 0}
                      reverse={milestone.reverse}
                    />
                  )
                })}
              </Steps>
            )
          })}
        </SectionInner>
      </SectionWrapper>
    </MainWrapper>
  )
}

const getMilestonesData = (milestones: AboutPageMilestoneStoryblok[]) => {
  const milestonesData: Record<
    number,
    {
      dateAndMonth: string
      text: RichtextStoryblok | undefined
      image: AssetStoryblok | undefined
      reverse: boolean
    }[]
  > = {}

  milestones.forEach(({ date, text, image }, index) => {
    if (!date) {
      return
    }

    const year = dayjs(date).year()
    const dateAndMonth = dayjs(date).format('DD/MM')

    if (!milestonesData[year]) {
      milestonesData[year] = []
    }

    milestonesData[year].push({
      dateAndMonth,
      text,
      image,
      reverse: index % 2 === 0,
    })
  })

  return Object.entries(milestonesData)
}

const MainWrapper = styled.div`
  margin: auto;
  margin-top: -1rem;
  overflow: hidden;

  ${media.sm} {
    margin: auto 5rem;
  }

  ${media.md} {
    margin: auto 7.5rem;
    margin-top: -4rem;
  }
`

export default Milestone
