import { useTheme } from '@material-ui/core'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { media } from '@/style/helpers'
import { AssetStoryblok } from '@/types/storyblok-component-types'

interface Props {
  image?: AssetStoryblok
  link?: string
  isLink?: boolean
  isExternalLink?: boolean
}

const PromotionBadge: FunctionComponent<Props> = ({
  image,
  link = '/',
  isLink = false,
  isExternalLink = false,
}) => {
  const { breakpoints } = useTheme()

  if (!image?.id) {
    return null
  }

  const imageProps = {
    image,
    sizes: `(max-width: ${breakpoints.values.md}px) 71px, 94px`,
  }

  const badgeProps = isLink
    ? {
        href:
          link.startsWith('/') || link.startsWith('http') ? link : `/${link}`,
        target: isExternalLink ? '_blank' : '_self',
      }
    : {}

  return (
    <StyledBadge {...badgeProps}>
      <StoryblokImage {...imageProps} />
    </StyledBadge>
  )
}

const StyledBadge = styled.a`
  right: 0.5rem;
  top: 0.5rem;
  position: absolute;
  z-index: 2;
  width: 71px;
  height: 71px;

  ${media.md} {
    right: 0.5rem;
    top: 0.5rem;
    width: 94px;
    height: 94px;
  }
`

export default PromotionBadge
