export function numberToFraction(decimal: number): string {
  if (decimal == 0) {
    return '0'
  } else if (decimal >= 1) {
    const remainder = decimal % 1
    const fullNumber = Math.floor(decimal)
    if (remainder) {
      return `${fullNumber} 1/${1 / remainder}`
    }
    return `${fullNumber}`
  } else {
    return `1/${1 / decimal}`
  }
}
