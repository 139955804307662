import { Accordion, AccordionDetails, Switch } from '@material-ui/core'
import styled from 'styled-components'
import { CustomAccordionSummary } from '@/app/common/components/AccordionSummary'

const BREAK_POINT = 600

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  isolation: isolate;
  padding-right: 0;
  width: 560px;
  height: 590px;
  max-height: 95vh;
  background: #ffffff;
  margin: auto;
  border-radius: 8px;

  @media only screen and (max-width: ${BREAK_POINT}px) {
    width: 100%;
    height: auto;
    max-height: initial;
    border: none;
    margin: auto 0 0;
    position: absolute;
    bottom: 0;
    border-radius: 8px 8px 0 0;
  }
`

const ModalLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: ${BREAK_POINT}px) {
    height: auto;
    max-height: 100vh;
  }
`

export const ModalFirstScreenLayout = styled(ModalLayout)`
  grid-template-rows: 53px 2px 1fr 96px;

  @media only screen and (max-width: ${BREAK_POINT}px) {
    height: 85vh;
  }
`

export const ContentScreen = styled.div`
  flex: 1;
  overflow-y: auto;
`

export const Logo = styled.div`
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.33em;
  color: #5016f4;
  padding: 1rem 1.5rem 0.25rem;
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
  }
`

export const ModalDescription = styled.div`
  font-family: 'GalanoGrotesque';
  font-weight: regular;
  padding: 1rem 1.5rem;
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.875rem;

  .header {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    line-height: 1.4rem;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .question {
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  .body {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .desc {
    margin-bottom: 0.125rem;
    padding-left: 1.25rem;
    position: relative;

    .check-icon {
      position: absolute;
      width: 12px;
      height: 12px;
      left: 0;
      top: 0.125rem;
    }
  }

  .link {
    a {
      font-weight: 700;
      text-decoration: underline;
      color: #000;
    }
    margin-bottom: 0.5rem;
  }
`

export const ButtonsGroupWrapper = styled.div`
  padding: 1rem 1.5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAK_POINT}px) {
    box-shadow: 0px 0px 4px 0px #00000040;

    display: flex;
    flex-direction: column-reverse;
  }
`

export const ButtonsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.625rem;

  @media only screen and (max-width: ${BREAK_POINT}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0.625rem;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0.65rem;
  }
`

export const AgreeButton = styled.button`
  color: white;
  background: #5016f4;
  padding: 0;
  height: 32px;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`
export const NecessaryAgreeButton = styled(AgreeButton)`
  width: 100%;
  opacity: 60%;
  height: 40px;
`

export const SettingButton = styled(AgreeButton)`
  width: 100%;
  background-color: transparent;
  color: rgb(80, 22, 244);
`

export const MinusIcon = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 12px;
  position: relative;
  :before {
    position: absolute;
    top: 50%;
    height: 2px;
    width: 100%;
    content: '';
    background: #5016f4;
  }
`

export const PlusIcon = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 12px;
  position: relative;
  :before {
    position: absolute;
    top: 50%;
    height: 2px;
    width: 100%;
    content: '';
    transform: translate(0, -50%);
    background: #5016f4;
  }
  :after {
    position: absolute;
    left: 50%;
    height: 100%;
    width: 2px;
    transform: translate(-50%, 0);
    content: '';
    background: #5016f4;
  }
`

export const CookiesAccordionSummary = styled(CustomAccordionSummary)`
  margin: 0px !important;
  padding: 0px !important;
  background: white !important;
  opacity: 1 !important;
  min-height: 42px !important;

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
`

export const AccordionSummaryContainer = styled.div`
  padding: 0px !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CookiesAccordion = styled(Accordion)`
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid #d9d9d9 !important;
  background: white !important;
  filter: none !important;
  opacity: 1 !important;
`

export const CookiesAccordionDetail = styled(AccordionDetails)`
  padding-left: 24px !important;
  display: grid !important;
  gap: 20px;
`

export const CookiesAccordionDetailText = styled.p`
  width: 100%;
  font-family: 'GalanoGrotesque';
  font-size: 10px;
  line-height: 14px;
`

export const AccordionHeader = styled.div`
  display: flex;
`

export const AccordionHeaderTitle = styled.h6`
  height: 14px;
  font-family: 'GalanoGrotesque';
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #000000;
`

export const IOSSwitch = styled(Switch).attrs(() => ({
  classes: {
    root: 'root',
    switchBase: 'switchBase',
    thumb: 'thumb',
    track: 'track',
    checked: 'checked',
  },
  disableRipple: true,
}))`
  &.root {
    width: 36px;
    height: 22px;
    padding: 0;
    margin: 8px;
    z-index: 3;
    display: flex;
  }

  .switchBase {
    padding: 1px;
    transform: translate(3px, 2px);

    &.checked {
      transform: translate(16px, 2px);
      color: white;
      & + .track {
        background: #fafafa;
        opacity: 1;
        border: 1px solid #52d869;
      }
    }

    &.focusVisible &.thumb {
      color: #52d869;
      border: 4px sold #fff;
    }
  }

  .thumb {
    width: 14px;
    height: 14px;
    border: 1px solid #52d869;
  }

  & .track {
    border-radius: 13px;
    border: 1.5px solid #bdbdbd;
    background-color: #fafafa;
    opacity: 1;
    transition:
      background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
      border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .checked {
    .thumb {
      background: #52d869;
    }

    & .track {
    }
  }
  .focusVisible {
  }
`

export const IOSNonToggle = styled.span`
  font-family: 'GalanoGrotesque';
  font-weight: regular;
  font-size: 14px;
  line-height: 14px;
  color: #8c8c8c;
`
